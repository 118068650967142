<template>
    <div v-if="isLoading" class="spinner-container">
        <div class="spinner"></div>
    </div>
</template>
<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Adjust the height as needed or use padding instead */
    padding: 20px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, .1);
    /* Adjust the size of the spinner here */
    width: 30px; 
    height: 30px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>