import { SelectedDocument } from '@/services/document.service'
import { ShareId } from '@/services/document.service'
import type { InjectionKey } from 'vue'

export const renderDashboardKey = Symbol() as InjectionKey<() => Promise<void>>

export const fillFoldersMoveModalKey = Symbol() as InjectionKey<
    () => Promise<void>
>

export const typeMoveModalKey = Symbol() as InjectionKey<{
    typeMoveModal: Ref<number>
    updateTypeMoveModal: (val: number) => void
}>

export const showMoveModalKey = Symbol() as InjectionKey<{
    showMoveModal: Ref<boolean>
    updateShowMoveModal: (val: boolean) => void
}>

export const selectedDocKey = Symbol() as InjectionKey<{
    selectedDoc: Ref<{ id: string; docID: string }>
    updateSelectedDoc: (document: { id: string; docID: string }) => void
}>

export interface DocumentWithContent extends SelectedDocument {
    content: string
}
export interface MultiDocSelectedDocuments {
    selectedDocuments: Ref<DocumentWithContent[]>
    updateSelectedDocuments: (documents: DocumentWithContent[]) => void
}

export const multiDocSelectedKey: InjectionKey<MultiDocSelectedDocuments> =
    Symbol('multiDocSelected')
export const documentKey = Symbol() as InjectionKey<
    ComputedRef<{
        document_id: Ref<string>
        is_public: boolean
        share_ids: ShareId[]
    }>
>

export interface ILoadTextTemplateModal {
    insertTextIntoAdvanced: (text: string) => void
    advancedTextArea: Ref<string>
}

export const loadTextTemplateModalKey: InjectionKey<ILoadTextTemplateModal> =
    Symbol('loadTextTemplateModal')
