import DocumentService, { SingleDocument } from '@/services/document.service'
import { UseQueryOptions, useQuery } from '@tanstack/vue-query'
import { Ref } from 'vue'

export const useDocument = (
    documentId: Ref<string>,
    options?: Partial<UseQueryOptions<SingleDocument>>
) => {
    const { $documentService } = useNuxtApp()
    const documentService = $documentService as DocumentService

    return useQuery({
        queryKey: ['documents', documentId],
        queryFn: () => documentService.getDocumentByDocID(toValue(documentId)),
        enabled: !!documentId,
        placeholderData: {
            Id: '',
            isPublicShare: false,
            shareIds: [],
            title: '',
            content: '',
            ownerId: '',
            editorId: '',
            collaborators: [],
            createdAt: 0,
            updatedAt: 0,
            isOwner: false,
            breadcrumbs: [],
        },

        ...options, // Spread in any additional options passed
    })
}
